<template>
  <div class="Services">
    <div class="bnServices">

    </div>

    <div class="secti-serv margin-sections">
      <v-container fluid>
        <h1><b>Beanar<b class="color-primary ">io</b> Services</b> </h1>

        <v-row class="mt-5">
          <v-col class="mb-6" cols="12 " md="2" sm="4" v-for="(i, index) in serv" :key="index">
            <v-card class="mx-auto px-3 mx-1 border-verd expande-y py-3  rounded-xl" mim-width="230" 
              outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 font-weight-bold text-left mb-1 mt-3">
                    <b>{{ i.name }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-left p-size-xs mb-4">
                    {{ i.descripcion }}
                  </v-list-item-subtitle>

                </v-list-item-content>
              </v-list-item>

            </v-card>
            <div class="mt-3 ml-8">
              <v-list-item-subtitle class="text-left title-icon mb-2">
                <b>Deliverables & Services</b>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-left p-size-xs ml-3" v-for="(c, index) in i.Deliverables_Services"
                :key="index">- {{ c }}</v-list-item-subtitle>
            </div>
            <div>
              <div class="list-logos mt-3 ml-8" v-for="(icon, index) in i.logos" :key="index">
                <v-list-item-subtitle v-if="icon.title" class="text-left title-icon mb-1">
                  <b>{{ icon.title }}</b>
                </v-list-item-subtitle>
                <div class="list-icon-flex">
                  <img v-for="(lg, index) in icon.logos" :key="index" :src="lg" class="mr-1" width="20px" alt=""
                    srcset="" />
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <Wedo />

    <!-- <v-container>
      <h1 class="mt-12">Industrias con las que trabajamos</h1>
    </v-container>
    <v-container>
      <h1 class="mt-12">Tecnologías principales</h1>
    </v-container> -->
  </div>
</template>
<script>
import Wedo from "@/components/wedo.vue";
export default {
  name: "Services",
  components: {
    Wedo,
  },
  data: () => ({
    serv: [
      {
        expand: false,
        name: "Discovery",
        Deliverables_Services: [
          "Time & Cost Estimation",
          "Product Requirements Document",
          "Architecture documentation",
          "Roadmap",
        ],
        logos: [
          {
            title: null,
            logos: [
              require("@/assets/logos/jira.svg"),
              require("@/assets/logos/confluence.svg"),
              require("@/assets/logos/github.svg"),
            ],
          },
        ],

        descripcion:
          'An iterative process that aligns product concept & specifications with your business goals.',
      },
      {
        expand: false,
        name: "Design",
        Deliverables_Services: [
          "Usability audit*",
          "Layout wireframes",
          "UX/UI design",
          "Illustrations *",
          "Animations*",
          "Accesibility reports",
        ],
        logos: [
          {
            title: null,
            logos: [
              require("@/assets/logos/figma.svg"),
              require("@/assets/logos/adobexd.svg"),
              require("@/assets/logos/lottie.svg"),
              require("@/assets/logos/l_Sketch.svg"),
            ],
          },
        ],

        descripcion:
          `An engaging product that captivates user attention while maintaining usability and accesibility.`,
      },
      {
        expand: false,
        name: "Build",
        Deliverables_Services: [
          "Custom applications",
          "Source code",
          "Automated tests",
          "Tech documentation",
          "Security testing",
          "Architecture schemes",
        ],
        logos: [
          {
            title: "Backend",
            logos: [
              require("@/assets/logos/l_Node-js.svg"),
              require("@/assets/logos/l_php.svg"),
              require("@/assets/logos/l_python.svg"),
              require("@/assets/logos/l_Rust.svg"),
              require("@/assets/logos/l_c++.svg"),
            ],
          },
          {
            title: "Databases",
            logos: [
              require("@/assets/logos/l_MSQL.svg"),
              require("@/assets/logos/l_Mariadb.svg"),
              require("@/assets/logos/l_SQLServer.svg"),
              require("@/assets/logos/l_Redis.svg"),
              require("@/assets/logos/l_PostSQL.svg"),
              require("@/assets/logos/l_Firebase.svg"),
              require("@/assets/logos/l_DynamoDb.svg"),
            ],
          },
          {
            title: "Frontend",
            logos: [
              require("@/assets/logos/l_Vue.svg"),
              require("@/assets/logos/l_Angular.svg"),
              require("@/assets/logos/l_electron.svg"),
            ],
          },
          {
            title: "Mobile",
            logos: [
              require("@/assets/logos/l_OBJC.svg"),
              require("@/assets/logos/l_SWIFt.svg"),
              require("@/assets/logos/l_Java.svg"),
              require("@/assets/logos/l_Kotlin.svg"),
              require("@/assets/logos/l_Capacitor.svg"),
              require("@/assets/logos/l_Ionic.svg"),
              require("@/assets/logos/l_Flutter.svg"),
            ],
          },
        ],

        descripcion:
          "Build fast & shorten your time to market with a high quality MVP or an enterprise-scale platform.",
      },

      {
        expand: false,
        name: "Test",
        Deliverables_Services: [
          "Security report",
          "Unit testing report",
          "E2E testing report",
          "A-B testing report",
          "User persona flows",
          "QA report",
        ],
        logos: [
          {
            title: null,
            logos: [
              require("@/assets/logos/l_Jest.svg"),
              require("@/assets/logos/l_Cypress.svg"),
              require("@/assets/logos/l_Firebase.svg"),
              require("@/assets/logos/l_Xcode.svg"),
            ],
          },
        ],

        descripcion:
          "Be sure that your product meets spec., we’ll look at every nook and cranny for bugs.",
      },
      {
        expand: false,
        name: "Launch",
        Deliverables_Services: [
          "Continuous integration",
          "Infrastructure as Code",
          "Automated builds & deploys",
          "24/7 monitoring",
          "On-call management",
          "Reporting & analytics",
          "Real-time cloud logging",
        ],
        logos: [
          {
            title: null,
            logos: [
              require("@/assets/logos/github.svg"),
              require("@/assets/logos/l_googleCP.svg"),
              require("@/assets/logos/l_Firebase.svg"),
              require("@/assets/logos/l_AWS.svg"),
              require("@/assets/logos/l_Azure.svg"),
              require("@/assets/logos/l_huawei_Cloud_S.svg"),
              require("@/assets/logos/l_Kubernetes.svg"),
              require("@/assets/logos/l_Docker.svg"),
              require("@/assets/logos/l_Hashicorp.svg"),
              require("@/assets/logos/l_Balena.svg"),
            ],
          },
        ],

        descripcion:
          "We combine high-powered cloud services with modern DevOps practices to keep your app up scalable & running 24/7.",
      },
      {
        expand: false,
        name: "Innovate",
        Deliverables_Services: [
          "AI & ML",
          "Blockchain",
          "AR & VR",
          "Biometrics",
          "Domotics & IoT",
        ],
        logos: [
          {
            title: null,
            logos: [
              require("@/assets/logos/l_Domotics.svg"),
              require("@/assets/logos/l_AR_VR.svg"),
              require("@/assets/logos/l_Biometrocs.svg"),

            ],
          },
        ],

        descripcion:
          "We’re into the latest technologies to make sure you’ll stay ahead of the curve & on top of your competitors.",
      },
    ],
  }),
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";

.Services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bnServices {
  width: 100%;
  height: 400px;
  background-image: url("https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.secti-serv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 50px;
  padding-right: 50px;

}

.Wedo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-list--three-line .v-list-item .v-list-item__subtitle,
.v-list-item--three-line .v-list-item__subtitle {
  white-space: initial;
  -webkit-line-clamp: 100;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.list-logos {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.list-icon-flex {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  height: 20px;
}

.title-icon {
  color: #b1b1b1 !important;
}

.expande-y {
  transition: 0.5s ease-in-out !important;
}

.border-verd {
  // border: #e7ef0a5d 1px solid;
  border: none;
  background-color: none;
  margin-left: 10px !important;
  margin-right: 10px !important;
  box-shadow: rgba(0, 0, 0, 0.363) 0px 5px 10px !important;
}

.p-size-xs {
  font-size: 0.8rem !important;
  color: #b1b1b1 !important;
}
</style>
