<template>
  <div class="proyectI">
    <div class="banner mb-6" :style="ptoyectoInyect.cssProps"></div>
    <v-container class="">
      <CardContainer
        type="proyect"
        :title="ptoyectoInyect.name"
        :subTitle="ptoyectoInyect.description"
        :link_se_more="'https://ocho.life/'"
        :color="ptoyectoInyect.color"
        :app_And_plataform="ptoyectoInyect.app_And_plataform"
        :mockup="ptoyectoInyect.mockup"
        :context="ptoyectoInyect.context"
        :core="ptoyectoInyect.core"
      >
        <img
          slot="logo"
          class="mb-6 mt-6"
          width="150px"
          src="@/assets/logo-ocho.svg"
          alt=""
          srcset=""
        />
        <div></div>
      </CardContainer>
    </v-container>
  </div>
</template>
<script>
import CardContainer from "@/components/CardContainer.vue";
export default {
  name: "Team",
  components: {
    CardContainer,
  },
  data: () => ({
    show: false,
    ptoyectoInyect: {
      name: "Ocho App",
      client: "Ocho",
      type: "Desarrollo de plataforma y app",
      stack: ["Dispach", "Ocho App", "Ocho platform", "ocho cash"],
      cssProps: {
        backgroundImage: `url(${require("@/assets/portafolio/banner/ocho.png")})`,
      },
      banner: require("@/assets/portafolio/ocho-banner.jpg"),
      mockup: require("@/assets/portafolio/mockup/Mockup_ocho.png"),

      description:
        "Ocho es una aplicación multicategoría para compras y envíos a demanda, donde podrás encontrar restaurantes, supermercados, entradas a eventos, servicios de courier (mensajería), entrega de efectivo, etc.",
      color: "#FF5800",
      context: {
        title: "Contexto del negocio",
        description:
          "Ocho’s main goal is to provide a platform that’s accessible to everyone, that’s why we accept every type of payment method available, not only credit & debit cards, but also wallets and cash payments, making the on line shopping experience possible for the banked & the unbanked sectors alike.",
        mockup2: require("@/assets/portafolio/mockup/phone_ocho.png"),
      },
      app_And_plataform: {
        title: "3 diferentes apps",
        apps: [
          {
            name: "Customer App",
            descripcion:
              "The customer app allows users to order the products & services they want & and have them delivered to their doorsteps within minutes from purchase. Users can register their cards & wallets as well as save their addresses. The app also allows users to track in real time the driver assigned to their order.",
          },
          {
            name: "Ocho Driver",
            descripcion:
              "Through this app our dirvers are automatically assigned tasks (pickup & delivery) when a user orders a product. It lets drivers see detailed information of every task assigned to them.",
          },
          {
            name: "Dispatch Center",
            descripcion:
              "The Dispatch Center lets our Customer Support Team & supervisors track every order in real time, letting them know which driver is assigned to which order, the user the order belongs to & its ETA.",
          },
        ],
      },
      core: [
        require("@/assets/logos/l_Kotlin.svg"),
        require("@/assets/logos/l_Vue.svg"),
        require("@/assets/logos/l_Angular.svg"),
        require("@/assets/logos/l_Firebase.svg"),
        require("@/assets/logos/l_Xcode.svg"),
        require("@/assets/logos/l_Ionic.svg"),
      ],
     
    },
  }),
  mounted: function () {
    window.scrollTo(0, 0);
    // $vuetify.theme.dark = true;
  },
  methods: {
    Cambioo(n) {
      this.show = false;
      console.log(n);
      this.ActiveP = n;
      console.log(this.ActiveP);
      setTimeout(() => {
        this.show = true;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
.proyectI {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  background-color: #f2f2f2;

  .banner {
    height: 400px;
    width: 100%;
    background-color: transparent;
    // background-image: url("https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.scroll-x {
  display: flex;
  justify-content: flex-start !important;
  flex-wrap: nowrap !important;
  overflow: hidden;
  overflow-x: scroll;

  position: relative;
}
.gradient {
  position: absolute;
  height: 100%;
  width: 30%;
  top: 0;
  position: absolute;

  right: 0px;
  z-index: 1;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    #12121200 10%,
    #12121200 30%,
    #121212 100%
  );
}
.otrosPro {
  position: relative;
}
</style>
