<template>
<div class="jobs">

<v-container class="">
<Table />
</v-container>

    
</div>
    
</template>
<script>
import Table from '@/components/Table'
export default {
  name: "Jobs",
  components: {
 Table
  },
  data: () => ({
   
  }),
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
.jobs{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}
</style>
