<template>
  <div class="Portafolio">
    <div class="banner mb-6"></div>
    <v-container class="mt-12">
      <v-row class="mx-2 mb-12 px-12 px-mobile">
        <v-col
          class="mb-6"
          cols="12"
          md="12"
          sm="12"
          v-for="item in proyect"
          :key="item._id"
     
        >
          <div class="mx-auto rounded-xl flex-card"      >
            <v-img :src="item.banner" class="divi"></v-img>
            <div class="content-card divi pa-5">
              <v-card-title class="mt-2">
                <b
                  ><h2>{{ item.name }}</h2>
                </b>
              </v-card-title>

              <v-card-subtitle class="mt-2 mb-3 text-left">
                {{ item.description }}
              </v-card-subtitle>

              <v-card-actions class="mb-2">
                <v-btn color="primary" v-if="item.link"   rounded class="mb-3" link :href="item.link" target="_blank" >
                
                    <b class="ml-2 mr-2 color-dark">See more</b>
     
                </v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Team",

  data: () => ({
    show: false,
    proyect: [
      {
        _id: 0,
        name: "Lowa Solutions",
        client: "Lowa Solutions",
        type: "Desarrollo de plataforma",
        stack: ["vue.js"],
        banner: require("@/assets/portafolio/lowa-banner.jpg"),
        description:
          "Fuel management fleet tracking platform.",
        link: null,
      },
      {
        _id: 1,
        name: "Ocho App",
        client: "Ocho",
        type: "Desarrollo de plataforma y app",
        stack: ["vue.js"],
        banner: require("@/assets/portafolio/ocho-banner.jpg"),
        link: "/Proyect",
        description:
          "3 sided marketplace that solves all your needs through 1 platform.",
      },

      {
        _id: 2,
        name: "Wink",
        client: "wink",
        type: "Desarrollo de plataforma y app",
        stack: ["vue.js"],
        banner: require("@/assets/portafolio/wink-banner.jpg"),
        description:
          "Dynamic digital signage plus touchscreen kiosk operating system & hardware.",
        link: 'https://www.instagram.com/winkdigital.hn/',
      },
    ],
  }),
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {
    Cambioo(n) {
      this.show = false;
      console.log(n);
      this.ActiveP = n;
      console.log(this.ActiveP);
      setTimeout(() => {
        this.show = true;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
.Portafolio {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;

  .banner {
    height: 400px;
    width: 100%;
    background-color: aqua;
    background-image: url("https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.flex-card {
  display: flex;
  flex-direction: row;
  background-color: rgb(27, 27, 27);
  border-radius: 1em;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  .divi {
    width: 50%;
    height: 100%;
  }
  @include responsive(mobile) {
    flex-direction: column !important;
    justify-content: center;
    align-items: flex-start;
    .divi {
      width: 100%;
      height: 100%;
    }
  }
}

</style>
