<template>
  <div class="contact">
    <v-container class="wrap-contacto px-6">
      <v-row>
        <v-col class="mb-6" cols="12" md="5" sm="5">
          <h1 class="text-left color-primary">Contact us</h1>
          <p class="text-left">
            Share the details of your project – like scope, timeframes, or
            business challenges you'd like to solve. Our team will carefully
            study them and then we’ll figure out the next move together.
          </p>
          <lottie-player
            src="https://assets4.lottiefiles.com/packages/lf20_961idjbl.json"
            background="transparent"
            speed="1"
            style="width: 100%; height: 300px"
            loop
            autoplay
          ></lottie-player>

          <!-- <v-btn
            :color="flag_form == true ? 'primary' : ''"
            :class="flag_form == true ? 'mx-2 my-2 color-dark' : 'mx-2 my-2'"
            elevation="4"
            @click="flag_form = true"
            raised
            rounded
          >
            Project inquiries
          </v-btn>

          <v-btn
            :color="flag_form == false ? 'primary' : ''"
            raised
            :class="flag_form == false ? 'mx-2 my-2 color-dark' : 'mx-2 my-2'"
            @click="flag_form = false"
            rounded
          >
            Employment
          </v-btn> -->
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="wrap-form rounded-xl" cols="12" md="6" sm="6">
          <div class="wrap-form-contact mt-12 mb-12">
            <h1 class="mb-3">
              Contact us
            </h1>
            <v-form>
              <!-- <v-form v-model="valid"> -->
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Name"
                      required
                      filled
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>

                 
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="E-mail"
                      required
                      filled
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea   label="Description"
                      required
                      filled
                      rounded
                      dense rows="3">

                    </v-textarea>
                  </v-col>
                </v-row>
               
                <v-row>
                  <v-col>
                    <v-btn
                      rounded
                      class="mr-4 color-dark"
                      color="primary"
                      height="50px"
                      block
                    >
                      submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Team from "@/components/Team.vue";
export default {
  name: "contact",
  components: {
    Team,
  },
  data: () => ({
    flag_form: true,
  }),
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.wrap-contacto {
  margin-top: 200px;
  margin-bottom: 200px;
}
.wrap-form {
  display: flex;
  justify-content: center;
  align-items: center;

  border: #f2f2f21f 1px solid;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.472);
}

.wrap-form-contact {
  // background-color: #fff;
  width: 80%;
}
</style>
