<template>
  <div class="card border-redondo" :class="[type && `card-${type}`]">
    <div class="card-image" v-if="$slots.logo">
      <slot name="logo"></slot>
    </div>
    <div
      class="card-header"
      v-if="$slots.header || title"
      :class="headerClasses"
    >
      <slot name="header">
        <h4 class="card-title" v-if="type == 'card'">{{ title }}</h4>

        <p class="card-category parrafo px-12" v-if="subTitle">
          {{ subTitle }}
        </p>
      </slot>
    </div>

    <v-row slot="default" class="mt-5" v-if="mockup">
      <v-col cols="12" sm="12" lg="12" md="12">
        <img width="60%" :src="mockup" alt="" srcset="" />
      </v-col>
    </v-row>

    <v-row v-if="context">
      <v-col cols="12" sm="6" lg="6" md="6">
        <img width="200px" :src="context.mockup2" alt="" srcset="" />
      </v-col>
      <v-col class="mt-12 pr-12" cols="12" sm="6" lg="6" md="6">
        <h1 class="text-left" :style="`color:${color}`">
          {{ context.title }}
        </h1>
        <p class="parrafo text-left">{{ context.description }}</p>
      </v-col>
    </v-row>

    <div class="card-body" v-if="$slots.default">
      <slot></slot>
    </div>
    <div class="card-image" v-if="$slots['image-bottom']">
      <slot name="image-bottom"></slot>
    </div>
    <slot name="raw-content"></slot>

    <div class="app_And_plataform" v-if="app_And_plataform">
      <h1 :style="`color:${color}`">{{ app_And_plataform.title }}</h1>
      <v-row>
        <v-col
          v-for="(i, index) in app_And_plataform.apps"
          :key="index"
          cols="12"
          md="4"
          sm="6"
          lg="4"
        >
          <h4 class="text-left">{{ i.name }}</h4>
          <p class="parrafo text-left mt-2">{{ i.descripcion }}</p>
        </v-col>
      </v-row>
    </div>
    <div class="mx-12">
      <h1><b :style="`color:${color}`">Core</b> <b>technologies</b></h1>

      <v-row class="core-tec-proyect">
        <v-col
          v-for="(c, index) in core"
          :key="index"
          cols="6"
          md="2"
          sm="4"
          lg="2"
        >
          <div class="tectn">
            <img :src="c" alt="" srcset="" />
          </div>
        </v-col>
      </v-row>
    </div>

    <v-row
      class="card-footer"
      :class="footerClasses"
      v-if="link_se_more != null"
    >
      <v-col class="mt-6 mb-6" cols="12" sm="12" lg="12" md="12">
        <a class="se-more" :href="link_se_more" target="_blank">
          <v-btn :color="color" elevation="1" raised rounded>
            <b>See More</b>
          </v-btn>
        </a>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Card",
  props: {
    title: {
      type: String,
      description: "Card title",
    },
    subTitle: {
      type: String,
      description: "Card subtitle",
    },
    mockup: {
      type: String,
      description: "Card subtitle",
    },
    color: {
      type: String,
      description: "color",
    },
    link_se_more: {
      type: String,
      description: "Link for se more info",
    },
    type: {
      type: String,
      description: "Card type (e.g primary/danger etc)",
    },
    headerClasses: {
      type: [String, Object, Array],
      description: "Card header css classes",
    },
    core: {
      type: [String, Object, Array],
      description: "core",
    },
    bodyClasses: {
      type: [String, Object, Array],
      description: "Card body css classes",
    },
    context: {
      type: [String, Object, Array],
      description: "context body",
    },
    app_And_plataform: {
      type: [String, Object, Array],
      description: "Card body css classes",
    },
    footerClasses: {
      type: [String, Object, Array],
      description: "Card footer css classes",
    },
  },
};
</script>
<style >
</style>
<style  lang="scss">
@import "@/_responsive.scss";
@import "@/main.scss";
.border-redondo {
  border-radius: 1em !important;
}
.card {
  background-color: #fff;
  box-shadow: rgba(189, 189, 189, 0.294) 0px 2px 30px;
}
.card-proyect {
  margin-left: 10% !important;
  margin-right: 10% !important;
  transform: translateY(-200px);
  padding: 20px !important;
  @include responsive(mobile) {
    margin-left: 20px !important;
    margin-right: 20px !important;
    transform: translateY(-200px);
    //... all what you want
  }

  color: rgb(26, 26, 26) !important;
}
.se-more {
  text-decoration: none;
}
.app_And_plataform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.parrafo {
  font-size: 14px !important;
  text-align: justify;
}
.core-tec-proyect {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tectn {
  width: 100px;
  height: 100px;
  border-radius: 1em;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: #f2f2f2;
  }
  img {
    width: 60%;
  }
}
.core-tect {
  margin-left: 20% !important;
  margin-right: 20% !important;
}
</style>
