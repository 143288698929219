<template>
  <div class="Services">
    <div class="bnServices"></div>

    <v-container>
      <v-row class="mb-12 mt-12 ma-3">
        <img src="@/assets/texture/left.svg" class="left-texture" srcset="" />
      <img src="@/assets/texture/rigth.svg" class="rigth-texture" srcset="" />
        <v-col cols="12" md="12" sm="12">
       <b> <h1> What    <b class="color-primary">are we?</b></h1></b>  
          <p>
            Somos una empresa 100% hondureña. Fundada en el 2014; hemos ido
            creciendo en el medio de desarrollo de software como una de las
            compañías con mayores capacidades en la implementación de
            aplicaciones, utilizando las tecnologías de programación más nuevas,
            con el conocimiento necesario en varios ámbitos de la industria para
            poder aplicar estos conocimientos a la integración de diferentes
            sistemas, así como la creación de herramientas que le den un mayor
            valor a los procesos de diferentes empresas, tanto a lo interno,
            como frente a sus clientes. <br />
            <br />Nuestro mayor enfoque es la experiencia de usuario y la
            flexibilidad en la administración de todas las aplicaciones que
            desarrollamos, buscando dar independencia al usuario, pero también
            entregar una interfaz intuitiva, visualmente agradable y con la
            mayor utilidad posible.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <section class="teams-seccion">
      <div class="container teams-seccion pt-12">
  <v-row>
    <v-col>
 <b><h1>Our <b class="color-primary">Purpose</b> </h1></b>
    <p>
Help large companies to comply with their digital strategy, creating and implementing high-quality technological products and services.</p>
    </v-col>
   
  </v-row>
</div>
      <v-container class="mt-12">
        <!-- <v-row>
          <v-col cols="12" md="12" sm="12"
            ><h1 class="color-primary">Our Purpose</h1>
            <h3>
              Help large companies to comply with their digital strategy,
              creating and implementing high-quality technological products and
              services.
            </h3></v-col -->
          <!-- > -->
          <!-- <v-col cols="12" md="6" sm="6"
            ><img src="@/assets/nucleo.svg" width="50%" alt="" srcset=""
          /></v-col> -->
        <!-- </v-row> -->
      </v-container>
    </section>

    <section class="teams-seccion">
             <!-- <v-container fluid class="mt-12 mb-12">
        <v-row>
          <v-col
            v-for="(fo, index) in fotos_grup"
            :key="index"
            :cols="fo.cols"
            :md="fo.md"
            :sm="fo.sm"
          >
          <img :src="fo.img" width="100%" height="100%" alt="" srcset="">
          </v-col>
        </v-row>
      </v-container> -->
      <!-- <v-container>
        <Team />
      </v-container> -->
   
        <Equipo />
   
   
    </section>
  </div>
</template>
<script>
import Team from "@/components/Team.vue";
import Equipo from "@/components/Equipo.vue";
export default {
  name: "business",
  components: {
    Team,
    Equipo,
  },
  data: () => ({
    // fotos_grup: [
    //   {
    //     img: require("@/assets/fotoGrup/foto3.jpg"),
    //     cols: "12",
    //     md: "5",
    //     sm: "5",
    //   },
    //   {
    //     img: require("@/assets/fotoGrup/foto.jpg"),
    //     cols: "12",
    //     md: "4",
    //     sm: "4",
    //   },
    //   {
    //     img: require("@/assets/fotoGrup/copia.jpg"),
    //     cols: "12",
    //     md: "3",
    //     sm: "3",
    //   },
    //   {
    //     img: require("@/assets/fotoGrup/grupal.jpg"),
    //     cols: "12",
    //     md: "4",
    //     sm: "4",
    //   },
    //   {
    //     img: require("@/assets/fotoGrup/copia.jpg"),
    //     cols: "12",
    //     md: "3",
    //     sm: "3",
    //   },
    //   {
    //     img: require("@/assets/fotoGrup/foto1.jpg"),
    //     cols: "12",
    //     md: "5",
    //     sm: "5",
    //   },
    // ],
  }),
  mounted: function () {
    window.scrollTo(0, 0);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";
.Services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bnServices {
  width: 100%;
  height: 400px;
  background-image: url("https://images.unsplash.com/photo-1542744095-fcf48d80b0fd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2076&q=80");
  background-size: cover;
  background-position-y: -500px;
  @include responsive(mobile) {
    background-position-y: 0px;

    //... all what you want
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.secti-serv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 50px;
  padding-bottom: 50px;
}
.Wedo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.teams-seccion {
  width: 100%;
  height: auto;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
  flex-direction: column;
}
</style>
