<template>
  <v-container class="Team margin-sections">
    
    <div>
      <b>
        <h1>LEADING <b class="color-primary">TEAM</b></h1></b
      >
      <p class="mb-12">
        We are known for being the union of different talents that allows us to
        carry out our work with excellence and innovation:
      </p>
      
    </div>

    <v-row>
      <v-col cols="12" md="4" sm="6" xl="4" v-for="p in funder" :key="p.id">
        <div class="card-funder">
          <div class="perfil">
            <img :src="p.foto" class="foto" alt="" srcset="" />
            <img
              src="@/assets/Exagono_team.svg"
              class="exa st0"
              alt=""
              srcset=""
            />
          </div>
          <div class="info-funder my-6 mx-4 text-left">
            <h3 class="color-primary">{{ p.name }}</h3>
            <h4>{{ p.cargo }}</h4>
            <p>{{ p.descrip }}</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Equipo",

  data: () => ({
    funder: [
      {
        id: 0,
        name: "Atahualpa Erazo",
        foto: require("@/assets/Ata.png"),
        cargo: "General manager",
        descrip:
          "- Over 12 years of experience in development analysis, flow design and industry best practices for corporate and financial sector - ITIL, Project Managment and Agile Scrum certifications - Sales and Credit Card market experience",
      },
      {
        id: 1,
        name: "Victor Morales",
        foto: require("@/assets/Victor.png"),
        cargo: "Chief Technology Officer",
        descrip:
          "- Over 12 years of experience in development analysis, flow design and industry best practices for corporate and financial sector - ITIL, Project Managment and Agile Scrum certifications - Sales and Credit Card market experience",
      },
      {
        id: 2,
        name: "Ricardo Aguilar",
        foto: require("@/assets/Ricardo.png"),
        cargo: "Commercial Director",
        descrip:
          "- Over 12 years of experience in development analysis, flow design and industry best practices for corporate and financial sector \n- ITIL, Project Managment and Agile Scrum certifications \n- Sales and Credit Card market experience",
      },
    ],
    show: true,
  }),
  methods: {
    Cambioo(n) {
      this.show = false;
      console.log(n);
      this.ActiveP = n;
      console.log(this.ActiveP);
      setTimeout(() => {
        this.show = true;
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/_responsive.scss";
@import "@/main.scss";

.card-funder {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;

  .perfil {
    width: 40%;
    height: 100%;
    position: relative;
    .foto {
      width: 80%;
      z-index: 4 !important;
      position: absolute;
      left: 10%;
      top: -10px;
    }
    .exa {
      position: absolute;
      width: 130%;
      left: -13%;
      top: 50px;
      z-index: 2 !important;
    }
  }
  .info-funder {
    width: 60%;

    p {
      font-size: 12px !important;
      color: #f2f2f28a;
    }
  }
}

.st0 {
  fill: none;
  stroke: #e9ef0a;
  stroke-miterlimit: 1;
  animation: rotate 10s linear infinite;
  transform-origin: 50% 50%;
}
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
